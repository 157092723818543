import React, { useContext, useState } from 'react'
import VideoPlaceholder from '../../assets/video-content.png'
import { withTheme } from 'theming'
import useTemplateHook from '../template/templateHook'
import AudioIcon from '../../assets/audio-content-badge.svg'
import LoaderTile from './loaderTile'
import PlaylistDecorater from '../../assets/playlist-decorator-related.svg'
import PlaylistIcon from '../../assets/playlist-icon-related.svg'
import { useTranslation } from 'react-i18next'
import ProgressBar from '../progressBar/progressBar'
import { isEmpty } from 'lodash'
import { TemplateContext } from '../../context/template'
import {
  // convertCardDuration,
  getRemainingTime,
} from '../../utils/helper'
import { ContentContext } from '../../context/content'
import { AppContext } from '../../context/app'

const NewContentCard = (props) => {
  const { data, fontFormat, textStyle, type, isEdit = false, theme } = props
  const { accountObj } = useContext(AppContext)
  const { contentDetailTemplateData } = useContext(TemplateContext)
  const { getResumeTimestamp } = useContext(ContentContext)
  // const navigate = useNavigate()
  const { t } = useTranslation()
  const { customNavigate, getTemplateImage } = useTemplateHook()
  const [imgLoaded, setImgLoaded] = useState(false)

  const handleClick = () => {
    if (isEdit) return
    if (data?.video_type === 'PLAYLIST' || data?.audio_type === 'PLAYLIST') {
      customNavigate('DIRECT', `/playlist/${data?.content_id}`)
      return
    }
    if (data?.video_type === 'MPVP' || data?.audio_type === 'MPAP') {
      customNavigate('DIRECT', `/parent-content/${data?.content_id}`)
    } else {
      if (
        (accountObj?.od_resource_allowances?.player_page && data.video_type === 'MPVC') ||
        data.video_type === 'MPAC'
      ) {
        customNavigate('DIRECT', `/series-watch/${data?.content_id}`)
        return
      }
      customNavigate('DIRECT', `/content/${data?.content_id}`)
    }
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!contentDetailTemplateData?.data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = contentDetailTemplateData?.data?.style_config[parentKey]?.data.filter(
      (info) => info.key === childKey,
    )

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className="relative overflow-hidden transform transition duration-500 ease z-0"
      onClick={handleClick}
    >
      <div className="bg-black flex justify-center relative rounded xl:rounded-lg overflow-hidden">
        {data?.asset_type === 'AUDIO' && (
          <img
            style={{ width: '24px', height: '24px' }}
            className="absolute right-0 m-2 z-20"
            src={AudioIcon}
            alt="AudioIcon"
          />
        )}
        <>
          {!imgLoaded && <LoaderTile />}
          <img
            src={getTemplateImage(data?.thumbnail)}
            className={`w-full h-full object-center`}
            alt={data?.title}
            style={{ display: !imgLoaded ? 'none' : 'block' }}
            onLoad={() => setImgLoaded(true)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = VideoPlaceholder
            }}
          />
        </>
        {data?.duration_in_s && typeof data?.duration_in_s === 'number' ? (
          <div className="absolute z-10 w-full bottom-0 px-5">
            {/* <div className="flex w-full justify-end">
              <div
                style={{ background: '#00000060', padding: '1.25px' }}
                className="rounded text-xs text-white flex justify-center items-center"
              >
                {convertCardDuration(data?.duration_in_s)}
              </div>
            </div> */}

            <ProgressBar
              bgColor={
                getResumeTimestamp(data?.content_id) &&
                getRemainingTime(data?.duration_in_s, getResumeTimestamp(data?.content_id))
                  ? '#bbbbbb'
                  : 'transparent'
              }
              barClass={'mb-2 mt-2'}
              progressWidth={`${Number(
                (getResumeTimestamp(data?.content_id) / data?.duration_in_s) * 100,
              )}%`}
              progressColor={
                getResumeTimestamp(data?.content_id) &&
                getRemainingTime(data?.duration_in_s, getResumeTimestamp(data?.content_id))
                  ? getStyleValue('play_button', 'background_color1', '#bbfa33')
                  : 'transparent'
              }
            />
          </div>
        ) : null}

        {data.video_type === 'PLAYLIST' || data.audio_type === 'PLAYLIST' ? (
          <>
            <img
              src={PlaylistDecorater}
              className="w-full object-cover rounded-lg absolute z-10"
              alt=""
              style={{ right: '0', bottom: '0' }}
            />
            <p
              style={{
                marginBlock: '0',
                right: '3%',
                bottom: '3%',
                gap: '0 2ch',
                fontSize: 'max(.55vw,12px)',
              }}
              className="absolute z-10 flex justify-center items-center"
            >
              <span className="font-bold text-black">{t('playlist')}</span>
              <img src={PlaylistIcon} alt="" />
            </p>
          </>
        ) : null}
      </div>
      <div
        style={textStyle ? { ...textStyle } : { color: theme.c4 }}
        // style={{ color: '#fff' }}
        className=" py-[0.5rem] "
      >
        <div className="mb-[0.5rem] flex justify-between items-center ">
          <p
            className={`text-[11px] md:text-[15px] ${
              fontFormat ? fontFormat : 'font-bold'
            } line-clamp-2 text-left`}
          >
            {type === 'content' ? data?.title : data?.name}
          </p>
        </div>
      </div>
    </div>
  )
}

export default withTheme(NewContentCard)
