import React from 'react'
// import CustomImage from '../../components/customImage/customImage'
// import HeroImg from '../../assets/pricing-summary-hero.png'
import { withTheme } from 'theming'
import Subscription from '../../components/pricing/subscription'
import { useTranslation } from 'react-i18next'

const PricingPage = ({ theme }) => {
  const { t } = useTranslation()
  return (
    <div className="relative">
      <div className=" w-full flex justify-center ">
        <div className="mt-6 xl:mt-[115px] flex flex-col items-center w-full z-10">
          <p className="mb-0 text-white xl:text-[28px] font-bold">
            {' '}
            {t('choose_membership_plans_caps')}
          </p>
          <p style={{ color: '#b5b5b5' }} className="mb-0 text-xs lg:text-sm italic">
            {t('choose_plan_that_works_friends')}
          </p>
          <div className="mt-4 w-[94%] lg:w-[905px]">
            <Subscription />
          </div>
        </div>
      </div>
      <div
        className="hidden xl:block absolute top-0 w-full"
        style={{
          aspectRatio: '1920/322',
        }}
      >
        <div className="relative w-full">
          {/* <CustomImage imageUrl={HeroImg} imgClass="w-full h-full" loaderClass={'h-64'} /> */}
          <div
            style={{
              height: '191px',
              backgroundImage: `linear-gradient(to bottom, rgba(27, 36, 47, 0), rgba(27, 36, 47, 0.91) 70%, ${theme.c1})`,
            }}
            className="w-full absolute bottom-0"
          />
        </div>
      </div>
    </div>
  )
}

export default withTheme(PricingPage)
